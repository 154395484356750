export enum PathEnum {
  error = '/404',
  cart = '/cart',
  voucher = '/cart/voucher',
  course = '/course/',
  customer = '/customers',
  customerPassword = '/customers/password',
  customerCourses = '/customer/orders',
  customerManuelCourses = '/customer/manuel-courses',
  customerState = '/customer/state',
  customerFavorites = '/favorites',
  bookmark = '/bookmarks',
  dashboardData = '/customer/dashboard',
  customerInvoice = '/customer-invoice',
  customerCertificate = '/customer-certificate',
  scope = '/scope',
  chambers = '/chambers',
  messages = '/messages',
  configuration = '/configuration',
  content = '/messages/content',
  dataprotection = '/messages/dataprotection',
  terms = '/messages/terms',
  order = '/order',
  guestOrderId = '/guest-order',
  orderPosition = '/order/position',
  guestOrderPosition = '/guest-order/position',
  orderStatus = '/status',
  manuelOrder = '/order/manuel',
  orderSelection = '/order/selection',
  orderCustomer = '/order/customer',
  orderEvaluation = '/order/evaluation',
  orderFileUpload = '/order/file-upload',
  paymentSelection = '/order/payment-selection',
  guest = '/guest-order/guest',
  guestSummary = '/guest-order/guest-summary',
  guestOrder = '/guest-order/order',
  guestOrderFileUpload = '/guest-order/file-upload',
  guestPaymentSelection = '/guest-order/payment-selection',
  orderComplete = '/order/order-complete',
  guestOrderComplete = '/guest-order/guest-order-complete',
  orderSummary = '/order/summary',
  organizer = '/organizer',
  professionalscope = '/professionalscope',
  search = '/search',
  fachanwaltskurs = 'fachanwaltslehrgaenge',
  fortbildung = 'fortbildungen-15-fao',
  searchSuggestCity = '/search/suggestCity',
  searchCity = '/search/city',
  sitemap = '/sitemap',
  summary = '/order/summary',
  contact = '/contact',
  newsletter = '/newsletter',
  warenkorb = '/warenkorb',
  city = 'stadt',
  online = 'online-seminare',
  selfstudies = 'selbststudium',
  present = 'praesenzschulung',
  certifification = '/lernerfolgskontrolle',
  paymentMethod = '/order/payment',
  guestPaymentMethod = '/guest-order/payment'
}
