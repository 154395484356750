import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../config.service';
import { SiteConfiguration } from '../configuration/site-configuration';
import { PathEnum } from '../enums/path-enum';
import { BookmarkOverviewResponse } from '../models/bookmark-overview-response';
import { BookmarkResponse } from '../models/bookmark-response';
import { CartValidationResponse } from '../models/cart-validation-response';
import { ChambersJson } from '../models/chambers-json';
import { ContactRequest } from '../models/contact-request';
import { CourseRequestJson } from '../models/course-request-json';
import { CourseResponseJson } from '../models/course-response-json';
import { CustomerDataResponseJson } from '../models/customer-data-response-json';
import { CustomerJson } from '../models/customer-json';
import { CustomerRequestJson } from '../models/customer-request-json';
import { DataprotectionJson } from '../models/dataprotection-json';
import { EducationalStateJson } from '../models/educational-state-json';
import { FavoriteJson } from '../models/favorite-json';
import { ManuelOrderRequest } from '../models/manuel-order-request';
import { MessageJson } from '../models/message-json';
import { NewsletterRequest } from '../models/newsletter-request';
import { OrderCourseJson } from '../models/order-course-json';
import { OrderJson } from '../models/order-json';
import { OrderPatchRequestJson } from '../models/order-patch-request-json';
import { OrderPosition } from '../models/order-position';
import { OrderPositionInfoJson } from '../models/order-position-info-json';
import { OrderRequestJson } from '../models/order-request-json';
import { OrderShortJson } from '../models/order-short-json';
import { OrganizerInfoResponse } from '../models/organizer-info-response';
import { OrganizerResponseJson } from '../models/organizer-response-json';
import { PaymentInfoResponse } from '../models/payment-info-response';
import { ReferentInfo } from '../models/referent-info';
import { SearchResponseJson } from '../models/search-response-json';
import { SuggestCityJson } from '../models/suggest-city-json';
import { SummaryResponseJson } from '../models/summary-response-json';
import { TermsJson } from '../models/terms-json';
import { CartJson } from '../models/cart-json';
import { KeycloakService } from 'keycloak-angular';
import { CustomerPackage } from '../models/customer-package';
import { AdvertInfo } from '../models/advert-info';

@Injectable({
  providedIn: 'root'
})
export class Api {
  // Resource: get, put, delete, post,

  constructor(
    private http: HttpClient,
    private urls: ConfigService,
    private keycloakService: KeycloakService
  ) {}

  public getChambers(): Observable<Array<ChambersJson>> {
    return this.http.get<any>(this.urls.getPath(PathEnum.chambers));
  }

  public getCoursesForYear(year: number, size?: number): Observable<any> {
    let param;
    if (size) {
      param = { size: size };
    }
    return this.http.get<any>(
      this.urls.getPath(PathEnum.customerCourses) + '/' + year,
      {
        params: param
      }
    );
  }

  public getOrders(size: number): Observable<any> {
    let param = { size: size };
    return this.http.get<any>(this.urls.getPath(PathEnum.customerCourses), {
      params: param
    });
  }

  public getManuelCoursesForYear(year: number): Observable<any> {
    let param;
    param = { manuel: true };
    return this.http.get<any>(
      this.urls.getPath(PathEnum.customerCourses) + '/' + year,
      {
        params: param
      }
    );
  }

  public getStateForYear(
    year: number
  ): Observable<Array<EducationalStateJson>> {
    return this.http.get<any>(
      this.urls.getPath(PathEnum.customerState) + '/' + year
    );
  }

  public getCourse(courseId: number): Observable<CourseResponseJson> {
    return this.http.get<CourseResponseJson>(
      this.urls.getPath(PathEnum.course) + courseId
    );
  }

  public getCourseOverview(
    courseId: number
  ): Observable<BookmarkOverviewResponse> {
    return this.http.get<BookmarkOverviewResponse>(
      this.urls.getPath(PathEnum.course) + courseId + '/overview'
    );
  }

  getCourseCertificationData(courseId: any) {
    return this.http.get<CourseResponseJson>(
      this.urls.getPath('/course-certification/') + courseId + '/certification'
    );
  }

  getCourseCertificationDataByUrlId(urlId: any) {
    return this.http.get<CourseResponseJson>(
      this.urls.getPath('/course-certification/attendance/') + urlId
    );
  }

  public putCourse(courseRequestJson: CourseRequestJson): Observable<any> {
    return this.http.put<any>(
      this.urls.getPath(PathEnum.course),
      courseRequestJson
    );
  }

  getCartCustomer() {
    return this.http.get<CustomerJson>(
      this.urls.getPath(PathEnum.cart + '/customer')
    );
  }

  public putCustomer(
    customerId: number,
    customerRequestJson: CustomerDataResponseJson
  ): Observable<any> {
    return this.http.put<any>(
      this.urls.getPath(PathEnum.customer) + '/' + customerId,
      customerRequestJson
    );
  }

  public getCustomer(): Observable<CustomerDataResponseJson> {
    let id;
    if (this.keycloakService.getKeycloakInstance().tokenParsed['customerId']) {
      id = this.keycloakService.getKeycloakInstance().tokenParsed['customerId'];
    } else {
      id = 0;
    }
    return this.http.get<CustomerDataResponseJson>(
      this.urls.getPath(PathEnum.customer + '/' + id)
    );
  }

  public changeCustomerPassword(): Observable<any> {
    return this.http.get<any>(this.urls.getPath(PathEnum.customerPassword));
  }

  public getMessages(language: any): Observable<MessageJson> {
    const param = { language: language };
    return this.http.get<MessageJson>(this.urls.getPath(PathEnum.messages), {
      params: param
    });
  }

  public getConfiguration(aid?: string): Observable<SiteConfiguration> {
    if (aid) {
      return this.http.get<SiteConfiguration>(
        this.urls.getPath(PathEnum.configuration),
        { params: { aid: aid } }
      );
    }
    return this.http.get<SiteConfiguration>(
      this.urls.getPath(PathEnum.configuration)
    );
  }

  public getContent(type: string, language: any): Observable<MessageJson> {
    const param = { language: language };
    return this.http.get<MessageJson>(
      this.urls.getPath(PathEnum.content) + '/' + type,
      {
        params: param
      }
    );
  }

  public getSeoContent(
    id: number,
    type: string,
    language: any,
    cityId?: number
  ): Observable<MessageJson> {
    const param = { language: language };
    let typeName = 'seo-' + type;
    if (id) {
      typeName = typeName + '-' + id;
    }
    if (cityId) {
      typeName = typeName + '-' + cityId;
    }
    return this.http.get<MessageJson>(
      this.urls.getPath(PathEnum.content) + '/' + typeName,
      {
        params: param
      }
    );
  }

  public getDataProtection(language: any): Observable<DataprotectionJson> {
    const param = { language: language };
    return this.http.get<DataprotectionJson>(
      this.urls.getPath(PathEnum.dataprotection),
      {
        params: param
      }
    );
  }

  public getTermsAndConditions(language: any): Observable<TermsJson> {
    const param = { language: language };
    return this.http.get<TermsJson>(this.urls.getPath(PathEnum.terms), {
      params: param
    });
  }

  public getOrder(orderId: number, parameter: any): Observable<OrderJson> {
    return this.http.get<OrderJson>(
      this.urls.getPath(PathEnum.order) + '/' + orderId,
      { params: parameter }
    );
  }

  public getGuestOrder(
    orderId: number,
    parameter: any,
    token: any
  ): Observable<OrderJson> {
    return this.http.get<OrderJson>(
      this.urls.getPath(PathEnum.guestOrderId) + '/' + orderId,
      { params: parameter, headers: { 'X-Order': token } }
    );
  }

  getCustomerPaymentMethod(): Observable<any> {
    return this.http.get<any>(this.urls.getPath(PathEnum.paymentMethod));
  }

  setCustomerPaymentMethod(paymentMethod: string): Observable<any> {
    return this.http.put<any>(this.urls.getPath(PathEnum.paymentMethod), {
      paymentMethod: paymentMethod
    });
  }

  getGuestPaymentMethod(token: any): Observable<any> {
    return this.http.get<any>(this.urls.getPath(PathEnum.guestPaymentMethod), {
      headers: { 'X-Auth': token }
    });
  }

  setGuestPaymentMethod(paymentMethod: string, token: any): Observable<any> {
    return this.http.put<any>(
      this.urls.getPath(PathEnum.guestPaymentMethod),
      { paymentMethod: paymentMethod },
      { headers: { 'X-Auth': token } }
    );
  }

  public getOrderPosition(
    orderPositionId: number,
    parameter: any
  ): Observable<OrderPosition> {
    return this.http.get<OrderPosition>(
      this.urls.getPath(PathEnum.orderPosition) + '/' + orderPositionId,
      { params: parameter }
    );
  }

  public getOrderCustomer(): Observable<OrderShortJson> {
    return this.http.get<OrderShortJson>(
      this.urls.getPath(PathEnum.orderCustomer)
    );
  }

  getOrderEvaluationInfo(orderId: any) {
    return this.http.get<OrderShortJson>(
      this.urls.getPath(PathEnum.orderEvaluation) + '/' + orderId
    );
  }

  public putOrderCustomer(
    orderCustomerRequest: CustomerRequestJson
  ): Observable<any> {
    return this.http.put<any>(
      this.urls.getPath(PathEnum.orderCustomer),
      orderCustomerRequest
    );
  }

  public putGuest(request: any): Observable<HttpResponse<any>> {
    const token = localStorage.getItem('rf-cart');
    if (token) {
      return this.http.put<any>(this.urls.getPath(PathEnum.guest), request, {
        observe: 'response',
        headers: { 'X-Auth': token }
      });
    } else {
      return this.http.put<any>(this.urls.getPath(PathEnum.guest), request, {
        observe: 'response'
      });
    }
  }

  public getOrderSummary(): Observable<HttpResponse<SummaryResponseJson>> {
    return this.http.get<SummaryResponseJson>(
      this.urls.getPath(PathEnum.orderSummary),
      { observe: 'response' }
    );
  }

  public getGuestSummary(
    cartToken: string
  ): Observable<HttpResponse<SummaryResponseJson>> {
    return this.http.get<SummaryResponseJson>(
      this.urls.getPath(PathEnum.guestSummary),
      { observe: 'response', headers: { 'X-Auth': cartToken } }
    );
  }

  public putOrder(orderRequest: OrderRequestJson): Observable<any> {
    return this.http.put<any>(this.urls.getPath(PathEnum.order), orderRequest, {
      observe: 'response'
    });
  }

  public putGuestOrder(
    orderRequest: OrderRequestJson,
    cartToken: string
  ): Observable<any> {
    return this.http.put<any>(
      this.urls.getPath(PathEnum.guestOrder),
      orderRequest,
      { observe: 'response', headers: { 'X-Auth': cartToken } }
    );
  }

  public putOrderFile(file: Blob) {
    let formParams = new FormData();
    formParams = (formParams.append('file', <any>file) as any) || formParams;
    return this.http.post<any>(
      this.urls.getPath(PathEnum.orderFileUpload),
      formParams
    );
  }

  public putGuestOrderFile(file: Blob) {
    let formParams = new FormData();
    formParams = (formParams.append('file', <any>file) as any) || formParams;
    return this.http.post<any>(
      this.urls.getPath(PathEnum.guestOrderFileUpload),
      formParams
    );
  }

  getGuest(cartToken: string): Observable<HttpResponse<CustomerJson>> {
    return this.http.get<CustomerJson>(this.urls.getPath(PathEnum.guest), {
      observe: 'response',
      headers: { 'X-Auth': cartToken }
    });
  }

  public patchPosition(
    orderPositionId: number,
    orderPatchRequest: OrderPatchRequestJson
  ): Observable<any> {
    return this.http.patch<any>(
      this.urls.getPath(PathEnum.orderPosition) + '/' + orderPositionId,
      orderPatchRequest
    );
  }

  public getOrganizer(
    organizerId: number,
    includeScopes: boolean
  ): Observable<OrganizerResponseJson> {
    let requestURL = this.urls.getPath(PathEnum.organizer) + '/' + organizerId;
    if (includeScopes) {
      requestURL += '?includeScopes=true';
    }
    return this.http.get<OrganizerResponseJson>(requestURL);
  }

  public getOrganizers(): Observable<Array<OrganizerInfoResponse>> {
    return this.http.get<Array<OrganizerInfoResponse>>(
      this.urls.getPath(PathEnum.organizer)
    );
  }

  public getSearch(searchParams): Observable<SearchResponseJson> {
    Object.keys(searchParams).forEach((key) => {
      if (searchParams[key] == null) {
        delete searchParams[key];
      }
    });
    return this.http.get<SearchResponseJson>(
      this.urls.getPath(PathEnum.search),
      { params: searchParams }
    );
  }

  public getSuggestCity(searchParam: any): Observable<Array<SuggestCityJson>> {
    return this.http.get<Array<SuggestCityJson>>(
      this.urls.getPath(PathEnum.searchSuggestCity),
      { params: searchParam }
    );
  }

  public getCity(): Observable<Array<SuggestCityJson>> {
    return this.http.get<Array<SuggestCityJson>>(
      this.urls.getPath(PathEnum.searchCity)
    );
  }

  public getCityName(cityId: number): Observable<SuggestCityJson> {
    return this.http.get<SuggestCityJson>(
      this.urls.getPath(PathEnum.searchCity + '/' + cityId)
    );
  }

  public getSitemap(): Observable<any> {
    return this.http.get<any>(this.urls.getPath(PathEnum.sitemap));
  }

  sendContactRequest(data: ContactRequest): Observable<any> {
    return this.http.put<any>(this.urls.getPath(PathEnum.contact), data);
  }

  registerNewsletter(data: NewsletterRequest): Observable<any> {
    return this.http.put<any>(this.urls.getPath(PathEnum.newsletter), data);
  }

  getOrderForScope(
    scopeId: number,
    year: number
  ): Observable<Array<OrderPositionInfoJson>> {
    return this.http.get<Array<OrderPositionInfoJson>>(
      this.urls.getPath(PathEnum.customerState) + '/' + year + '/' + scopeId
    );
  }

  getFavorites() {
    return this.http.get<Array<FavoriteJson>>(
      this.urls.getPath(PathEnum.customerFavorites)
    );
  }

  saveFavorite(favorite: FavoriteJson): any {
    return this.http.put<any>(
      this.urls.getPath(PathEnum.customerFavorites),
      favorite,
      { observe: 'response' }
    );
  }

  deleteFavorite(id: number) {
    return this.http.delete<any>(
      this.urls.getPath(PathEnum.customerFavorites) + '/' + id
    );
  }

  bookmark(id: number) {
    return this.http.put<any>(this.urls.getPath(PathEnum.bookmark), {
      courseId: id
    });
  }

  checkBookmark(id: number): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.urls.getPath(PathEnum.bookmark) + '/' + id, {
      observe: 'response'
    });
  }

  unbookmark(id: number) {
    return this.http.delete<any>(
      this.urls.getPath(PathEnum.bookmark) + '/' + id
    );
  }

  getBookmarks(): Observable<BookmarkResponse> {
    return this.http.get<any>(this.urls.getPath(PathEnum.bookmark));
  }

  saveOrder(orderId: number, requestJson: ManuelOrderRequest) {
    if (orderId) {
      return this.http.put<any>(
        this.urls.getPath(PathEnum.manuelOrder + '/' + orderId),
        requestJson
      );
    } else {
      return this.http.post<any>(
        this.urls.getPath(PathEnum.manuelOrder),
        requestJson
      );
    }
  }

  getManuelCourse(orderId: any) {
    return this.http.get<OrderJson>(
      this.urls.getPath(PathEnum.manuelOrder) + '/' + orderId
    );
  }

  deleteManuelCourse(orderId: number) {
    return this.http.delete<any>(
      this.urls.getPath(PathEnum.manuelOrder) + '/' + orderId
    );
  }

  updateCompleteStatus(type: string, id: number, status: string) {
    return this.http.post<any>(
      this.urls.getPath(PathEnum.order) + PathEnum.orderStatus,
      {
        id: id,
        status: status,
        type: type
      }
    );
  }

  getRakLetter(
    scopeIds: Array<Number>,
    orderIds: Array<Number>,
    manuelIds: Array<number>,
    chamberId: number,
    chamberMemberId: string,
    year: number,
    documentType: string
  ): Observable<Blob> {
    const headers = new HttpHeaders({ Accept: documentType });
    return this.http.post<any>(
      this.urls.getPath(PathEnum.customerState) + '/' + year + '/letter',
      {
        scopeIds: scopeIds,
        orderIds: orderIds,
        manuelOrderIds: manuelIds,
        chamberId: chamberId,
        chamberMemberId: chamberMemberId
      },
      { headers, responseType: 'blob' as 'json' }
    );
  }

  addToCart(selectedOrderCourse: OrderCourseJson, token?: string) {
    if (token) {
      return this.http.put<any>(
        this.urls.getPath(PathEnum.cart),
        selectedOrderCourse,
        { headers: { Authorization: 'Bearer ' + token } }
      );
    } else {
      return this.http.put<any>(
        this.urls.getPath(PathEnum.cart),
        selectedOrderCourse
      );
    }
  }

  getCart() {
    return this.http.get<any>(this.urls.getPath(PathEnum.cart));
  }

  getCartByPositions(cart: CartJson) {
    return this.http.post<any>(this.urls.getPath(PathEnum.cart), cart);
  }

  getCartAmount(): Observable<HttpResponse<any>> {
    return this.http.head<any>(this.urls.getPath(PathEnum.cart), {
      observe: 'response'
    });
  }

  deleteCartPosition(position: number) {
    return this.http.delete<any>(
      this.urls.getPath(PathEnum.cart) + '/' + position
    );
  }

  addVoucherToCart(voucher: string) {
    return this.http.get<any>(
      this.urls.getPath(PathEnum.voucher) + '/' + voucher
    );
  }

  removeVoucherFromCart(voucher: string) {
    return this.http.delete<any>(
      this.urls.getPath(PathEnum.voucher) + '/' + voucher
    );
  }

  removeFromPackage(positionId: number) {
    return this.http.delete<any>('/api/cart/' + positionId + '/package');
  }

  deleteGuestCartPosition(position: number, token: string) {
    return this.http.delete<any>(
      this.urls.getPath(PathEnum.cart) + '/guest/' + position,
      { headers: { 'X-Auth': token } }
    );
  }

  validateCart(): Observable<CartValidationResponse> {
    const cart = JSON.parse(localStorage.getItem('cart'));
    if (cart) {
      cart.voucherCodes = [
        cart.cartVoucher?.voucherCode,
        ...cart.positions.map((p) => p.voucher?.voucherCode)
      ];
      return this.http.put<any>(
        this.urls.getPath(PathEnum.order) + '/validate-cart',
        cart
      );
    } else {
      return this.http.put<any>(
        this.urls.getPath(PathEnum.order) + '/validate-cart',
        {}
      );
    }
  }

  getInvoice(orderId: string): Observable<HttpResponse<Blob>> {
    return this.http.request(
      'get',
      this.urls.getPath(PathEnum.customerInvoice) + '/' + orderId,
      {
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

  getCertificate(orderPositionId: number): Observable<Blob> {
    return this.http.get<any>(
      this.urls.getPath(PathEnum.customerCertificate) + '/' + orderPositionId,
      { responseType: 'blob' as 'json' }
    );
  }

  getModuleCertificate(
    orderPositionId: number,
    moduleId: number
  ): Observable<Blob> {
    return this.http.get<any>(
      this.urls.getPath(PathEnum.customerCertificate) +
        '/' +
        orderPositionId +
        '/module/' +
        moduleId,
      { responseType: 'blob' as 'json' }
    );
  }

  getCustomerCertificationData(
    courseId: number
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      this.urls.getPath(PathEnum.customerCertificate) +
        '/certification/' +
        courseId,
      { observe: 'response' }
    );
  }

  getCustomerCertificationDataForUrlId(
    urlId: any
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      this.urls.getPath(PathEnum.customerCertificate) + '/attendance/' + urlId,
      { observe: 'response' }
    );
  }

  findCustomerCertificationData(value: any): Observable<HttpResponse<any>> {
    const param = { email: value.email };
    return this.http.get<any>(
      this.urls.getPath(PathEnum.customerCertificate) +
        '/certification/' +
        value.courseId +
        '/order/' +
        value.orderNumber,
      {
        params: param,
        observe: 'response'
      }
    );
  }

  checkCustomerCertification(
    request: any,
    courseId: number,
    orderNumber: number
  ): Observable<HttpResponse<any>> {
    return this.http.put<any>(
      this.urls.getPath(PathEnum.customerCertificate) +
        '/certification/' +
        courseId +
        '/order/' +
        orderNumber +
        '/certificate',
      request,
      { observe: 'response' }
    );
  }

  getDocument(positionId: number, documentId: number): Observable<Blob> {
    return this.http.get<any>(
      this.urls.getPath(PathEnum.orderPosition) +
        '/' +
        positionId +
        '/document/' +
        documentId,
      { responseType: 'blob' as 'json' }
    );
  }

  getGuestDocument(
    positionId: number,
    documentId: number,
    token: string
  ): Observable<Blob> {
    return this.http.get<any>(
      this.urls.getPath(PathEnum.guestOrderPosition) +
        '/' +
        positionId +
        '/document/' +
        documentId,
      {
        responseType: 'blob' as 'json',
        headers: { 'X-Order': token }
      }
    );
  }

  getCourseDocument(courseId: number, documentId: number): Observable<Blob> {
    return this.http.get<any>(
      this.urls.getPath(PathEnum.course) + courseId + '/document/' + documentId,
      { responseType: 'blob' as 'json' }
    );
  }

  getPaymentInfo(orderId: number): Observable<PaymentInfoResponse> {
    return this.http.get<any>(
      this.urls.getPath(PathEnum.order) + '/payment/' + orderId
    );
  }

  public getGuestPayment(
    orderId: number,
    parameter: any,
    token: any
  ): Observable<PaymentInfoResponse> {
    return this.http.get<PaymentInfoResponse>(
      this.urls.getPath(PathEnum.guestOrderId) + '/payment/' + orderId,
      { params: parameter, headers: { 'X-Order': token } }
    );
  }

  validateGuestPayment(token: any, id: string) {
    const request = {
      orderId: null,
      paymentId: id
    };
    return this.http.put<any>(
      this.urls.getPath(PathEnum.guestOrderId) + '/validate-payment',
      request,
      { headers: { 'X-Order': token } }
    );
  }

  validatePayment(orderId: number, id: string) {
    const request = {
      orderId: orderId,
      paymentId: id
    };
    return this.http.put<any>(
      this.urls.getPath(PathEnum.order) + '/validate-payment',
      request
    );
  }

  getAdverts(
    pageName: string,
    scopesIds?: Array<number>,
    types?: Array<string>
  ): Observable<AdvertInfo[]> {
    let httpParam = new HttpParams();
    if (scopesIds) {
      httpParam = httpParam.set('scopes', scopesIds.join(','));
    }
    if (types) {
      httpParam = httpParam.set('types', types.join(','));
    }
    return this.http.get<AdvertInfo[]>(
      this.urls.getPath('/banner') + '/' + pageName,
      { params: httpParam }
    );
  }

  getFaq() {
    return this.http.get<any>(this.urls.getPath('/faq'));
  }

  getReferent(referentId: number): Observable<ReferentInfo> {
    return this.http.get<any>(
      this.urls.getPath('/referent-infos') + '/' + referentId
    );
  }

  getReferents(): Observable<ReferentInfo[]> {
    return this.http.get<any>(this.urls.getPath('/referent-infos'));
  }

  loadCustomerPackages(
    course: CourseResponseJson
  ): Observable<CustomerPackage[]> {
    let requestUrl = '/api/customer/packages?';
    requestUrl += 'organizerId=' + course.organizer.id;
    requestUrl += '&type=' + course.type;
    for (const scope of course.professionalScopes) {
      requestUrl += '&scope=' + scope.id;
    }
    return this.http.get<any>(requestUrl);
  }

  updateCartPositionScope(
    id: number,
    scopeId: number,
    categoryId: number
  ): Observable<void> {
    let requestUrl = '/api/order/cart/' + id;
    const request = {
      scopeId: scopeId,
      categoryId: categoryId
    };
    return this.http.patch<any>(requestUrl, request);
  }

  getPaymentMethods() {
    let requestUrl = '/api/cart/payment-methods';
    return this.http.get<any>(requestUrl);
  }
}
